import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"
import easeInOutExpo from "../../../theme/easings"
import texturaRuido from "../../../../static/assets/images/texturas/textura-ruido.png"

const StyledStoryCard = styled.div`
  align-items: center;
  display: flex;
  height: 385px;
  justify-content: center;
  margin-bottom: 22px;
  pointer-events: none;
  width: 100%;

  @media ${device.sm} {
    height: 405px;
  }
  @media ${device.md} {
    height: 385px;
  }
  @media ${device.lg} {
    height: 470px;
  }

  &.is-small {
    .o-story-card__favcontainer {
      right: 0 !important;
      width: 40px !important;

      @media ${device.lg} {
        width: 45px !important;
      }
    }
  }
  &.story_card_top_2,
  &.story_card_bottom_0,
  &.story_card_bottom_1,
  &.story_card_bottom_2,
  &.story_card_bottom_3 {
    .o-story-card__infocontainer {
      @media ${device.sm} {
        padding-right: 0;
        width: 80%;
      }
    }
  }
  @media ${device.md} {
    &.is-overed {
      a {
        transform: scale(1.05);
      }
    }
    &.is-some-card-overed {
      a {
        transform: scale(0.9);
      }
    }
    &:hover {
      .o-story-card__bg {
        transform: scale(1);
      }
    }
  }
  a {
    display: block;
    height: 100%;
    pointer-events: auto;
    position: relative;
    transform: scale(1);
    transition: transform 500ms ${easeInOutExpo};
    width: 100%;
  }
  .o-story-card__bgcontainer {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    overflow: hidden;
    pointer-events: none;
  }
  .o-story-card__bg {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    z-index: 1;
    position: absolute;
    transition: transform 0.5s ${easeInOutExpo};
    @media ${device.md} {
      transform: scale(1.05);
    }
  }
  .o-story-card__bgnoise {
    width: 100%;
    height: 100%;
    background-image: url(${texturaRuido});
    background-position: center;
    background-size: cover;
    z-index: 2;
    position: absolute;
    pointer-events: none;
  }
  .o-story-card__bgblack {
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.3;
    z-index: 2;
    position: absolute;
  }
  .o-story-card__favcontainer {
    font-size: 0;
    pointer-events: none;
    position: absolute;
    right: -7px;
    top: 15px;
    width: 160px;
    z-index: 3;
    svg {
      height: auto;
      width: 100%;
    }
    @media ${device.md} {
      right: -9px;
      width: 190px;
    }
    @media ${device.lg} {
      right: -10px;
      width: 221px;
    }
  }
  .o-story-card__roadcontainer {
    font-size: 0;
    height: auto;
    left: -10px;
    pointer-events: none;
    position: absolute;
    top: 30px;
    width: 46px;
    z-index: 3;
    svg {
      color: ${props => props.theme.vars.creamCan};
      height: auto;
      width: 100%;
    }
    @media ${device.sm} {
      width: 50px;
      left: -17px;
    }
    @media ${device.md} {
      width: 60px;
      left: -14px;
    }
    @media ${device.lg} {
      left: -20px;
    }
    @media ${device.xl} {
      width: 76px;
      left: -24px;
    }
  }
  .o-story-card__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  .o-story-card__svg-roll {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    pointer-events: none;
    width: 100%;
  }
  .o-story-card__infocontainer {
    padding: 0 20px 20px;
    pointer-events: none;
    position: relative;
    width: 100%;
    z-index: 3;

    @media ${device.md} {
      padding: 0 25px 25px;
    }
    @media ${device.lg} {
      padding: 0 30px 30px;
    }
    @media ${device.xl} {
      padding: 0 50px 50px;
    }
  }
  .o-story-card__infodate {
    display: inline-block;
    color: ${props => props.theme.vars.white};
    font-family: ${props => props.theme.vars.tradeGot};
    font-weight: ${props => props.theme.vars.fontBold};
    font-size: 16px;
    padding-right: 20px;
  }
  .o-story-card__infospacer {
    width: 1px;
    height: 12px;
    background-color: #ffffff;
    opacity: 0.3;
    margin-right: 17px;
    display: inline-block;
  }
  .o-story-card__infolike {
    display: inline-block;
  }
  .o-story-card__infobottom {
    margin-top: 20px;
    color: #ffffff;

    p {
      font-size: 30px;
      font-weight: ${props => props.theme.vars.fontSemiBold};
      font-family: ${props => props.theme.vars.avenirNext};
    }
  }
  .o-story-card__infolike__icon {
    display: inline-block;
    width: 13px;
    height: 13px;
    color: white;
    margin-right: 10px;
    vertical-align: top;
  }
  .o-story-card__infolike__number {
    display: inline-block;
    vertical-align: top;
  }
  &.is-promoted {
    .o-story-card__svg-roll {
      padding-top: 40px;
      @media ${device.md} {
        padding-top: 45px;
      }
      @media ${device.lg} {
        padding-top: 50px;
      }
    }
  }
`

export default StyledStoryCard
