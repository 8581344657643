import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const SearchStyle = styled.section`
  position: relative;

  .m-grid-results {
    margin-top: -100px;
    &.no-more-results {
      margin-bottom: 25vw;
    }
  }
  &.exist-results {
    min-height: 100vh;
    min-height: calc(100vh - 195px);
  }
  &.no-results {
    height: 100vh;
    height: calc(100vh - 195px);
    min-height: 400px;
  }

  @media ${device.sm} {
    .m-grid-results {
      margin-top: 0;
      &.no-more-results {
        margin-bottom: 6vw;
      }
    }
    &.exist-results {
      min-height: calc(100vh - 145px);
    }
    &.no-results {
      height: calc(100vh - 145px);
      min-height: 450px;
    }
  }
  @media ${device.md} {
    .m-grid-results {
      &.no-more-results {
        margin-bottom: 10vw;
      }
    }
    &.exist-results {
      min-height: calc(100vh - 80px);
    }
    &.no-results {
      height: calc(100vh - 80px);
      min-height: 500px;
    }
  }
  @media ${device.lg} {
    .m-grid-results {
      &.no-more-results {
        margin-bottom: 8vw;
      }
    }
    &.no-results {
      min-height: 550px;
    }
  }
  @media ${device.xl} {
    .m-grid-results {
      &.no-more-results {
        margin-bottom: 6vw;
      }
    }
    &.exist-results {
      min-height: calc(100vh - 111px);
    }
    &.no-results {
      height: calc(100vh - 111px);
      min-height: 600px;
    }
  }
`

export const SearchWrapper = styled.div`
  .t-search__footer {
    margin-top: 130px;
  }
  .t-search__button-more {
    letter-spacing: 2px;
    cursor: pointer;

    span {
      font-family: ${props => props.theme.vars.tradeGot};
      font-weight: ${props => props.theme.vars.fontBold};
      font-size: 13px;
      margin-bottom: 8px;
    }
    .t-search__button-more__icon {
      svg {
        width: 12px;
        height: 5px;
      }
    }
  }

  @media ${device.sm} {
    .t-search__footer {
      margin-top: 170px;
    }
    .t-search__button-more {
      letter-spacing: 2px;
    }
  }
  @media ${device.md} {
    .t-search__footer {
      margin-top: 200px;
    }
  }
  @media ${device.lg} {
    .t-search__footer {
      margin-top: 280px;
    }
  }
  @media ${device.xl} {
    .t-search__footer {
      margin-top: 360px;
    }
  }
`

export const ButtonContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  margin-bottom: 170px;
  margin-top: 32px;
  width: 100%;

  .c-button--iconarrow_contentroll {
    color: ${props => props.theme.vars.charade};
  }
  .c-button--iconarrow_content {
    color: ${props => props.theme.vars.charade};
  }
  .c-button--iconarrow_icon {
    fill: ${props => props.theme.vars.tulipTree};
  }
  .c-button--iconarrow_iconroll {
    fill: ${props => props.theme.vars.tulipTree};
  }

  @media ${device.sm} {
    margin-bottom: 6vw;
    margin-top: 36px;
  }
  @media ${device.md} {
    margin-bottom: 10vw;
    margin-top: 83px;
  }
  @media ${device.lg} {
    margin-top: 98px;
    margin-bottom: 8vw;
  }
  @media ${device.xl} {
    margin-bottom: 6vw;
    margin-top: 74px;
  }
`

export const DegradateImg = styled.img`
  width: 100vw;
  position: absolute;
  z-index: -1;
`

export const HeaderResults = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
  padding-top: 140px;
  width: 100%;
  z-index: 2;

  @media ${device.sm} {
    padding-bottom: 80px;
    padding-top: 204px;
  }
  @media ${device.md} {
    padding-bottom: 82px;
    padding-top: 218px;
  }
  @media ${device.md} {
    padding-bottom: 82px;
    padding-top: 205px;
  }
  @media ${device.xl} {
    padding-bottom: 145px;
    padding-top: 222px;
  }
`

export const ResultsTitle = styled.h1`
  padding: 0 31px;
  line-height: 34px;
  font-size: 28px;
  font-weight: ${props => props.theme.vars.fontBold};
  font-family: ${props => props.theme.vars.avenirNext};
  color: ${props => props.theme.vars.midGray};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 12px;

  @media ${device.sm} {
    margin-bottom: 17px;
    font-size: 36px;
    line-height: normal;
  }
  @media ${device.md} {
    margin-bottom: 21px;
    font-size: 48px;
  }
  @media ${device.xl} {
    margin-bottom: 21px;
    font-size: 72px;
  }
`

export const CounterText = styled.p`
  color: ${props => props.theme.vars.titanWhite};
  font-family: ${props => props.theme.vars.openSans};
  font-size: 16px;
  font-weight: ${props => props.theme.vars.fontRegular};
  text-align: center;

  span {
    font-weight: ${props => props.theme.vars.fontSemiBold};
    color: ${props => props.theme.vars.goldenTainoi};
  }
`

export const NoResults = styled.div`
  color: ${props => props.theme.vars.titanWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  .t-search__danger-svg {
    width: 38px;
    height: 38px;
    fill: ${props => props.theme.vars.goldenTainoi};
    margin-bottom: 10px;
  }

  @media ${device.sm} {
    .t-search__danger-svg {
      width: 65px;
      height: 65px;
      margin-bottom: 27px;
    }
  }
`
export const NoResultsTitle = styled.h1`
  font-size: 18px;
  font-weight: ${props => props.theme.vars.fontBold};
  font-family: ${props => props.theme.vars.avenirNext};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;

  @media ${device.sm} {
    font-size: 30px;
    margin-bottom: 17px;
  }
  @media ${device.xl} {
    font-size: 36px;
  }
`

export const Disclaimer = styled.p`
  font-size: 16px;
  font-weight: ${props => props.theme.vars.fontRegular};
  font-family: ${props => props.theme.vars.openSans};
  text-align: center;
  line-height: 24px;
  padding: 0 1px;

  @media ${device.sm} {
    font-size: 16px;
    color: ${props => props.theme.vars.midGray};
  }
  @media ${device.md} {
    padding: 0 120px;
  }
  @media ${device.lg} {
    padding: 0 215px;
  }
  @media ${device.xl} {
    padding: 0 388px;
  }
`
