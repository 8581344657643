import styled from "styled-components"
import { device } from "../../../../theme/mediaQueries"

const GridContainer = styled.div`
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  .m-grid-results__story-card {
    height: 350px;
    margin-bottom: 22px;

    .o-story-card__roadcontainer {
      left: -15px;
      top: 25px;
      width: 40px;
    }
    .o-story-card__favcontainer {
      top: 15px;
      width: 150px;
    }
    .o-story-card__infocontainer {
      .o-story-card__infodate {
        font-size: 14px;
      }
      .o-story-card__infobottom {
        p {
          font-size: 28px;
        }
      }
    }
  }

  @media ${device.sm} {
    .m-grid-results__story-card {
      height: 328px;
      margin-bottom: 18px;
      margin-right: 18px;
      width: calc((100% - 36px) / 3);

      &.m-grid-results__story-card_type-xs3 {
        margin-right: 0px;
      }
      .o-story-card__roadcontainer {
        left: -11px;
        top: 27px;
        width: 35px;
      }
      .o-story-card__favcontainer {
        top: 15px;
        right: -7px;
        width: 140px;
      }
      .o-story-card__infocontainer {
        .o-story-card__infodate {
          font-size: 12px;
        }
        .o-story-card__infobottom {
          p {
            font-size: 20px;
          }
        }
      }
    }
  }

  @media ${device.md} {
    .m-grid-results__story-card {
      height: 340px;
      margin-bottom: 24px;
      margin-right: 24px;
      width: calc((100% - 72px) / 4);

      &.m-grid-results__story-card_type-xs3 {
        margin-right: 24px;
      }
      &.m-grid-results__story-card_type-dk4 {
        margin-right: 0;
      }
      .o-story-card__infocontainer {
        padding: 0 20px 20px;
      }
    }
  }

  @media ${device.lg} {
    .m-grid-results__story-card {
      flex-basis: 255px;
      height: 380px;

      .o-story-card__roadcontainer {
        left: -13px;
        top: 30px;
        width: 40px;
      }
      .o-story-card__favcontainer {
        right: -7px;
        width: 160px;
      }
      .o-story-card__infocontainer {
        .o-story-card__infodate {
          font-size: 14px;
        }
        .o-story-card__infobottom {
          p {
            font-size: 24px;
          }
        }
      }
    }
  }

  @media ${device.xl} {
    .m-grid-results__story-card {
      flex-basis: 342px;
      height: 438px;

      .o-story-card__roadcontainer {
        width: 45px;
        left: -12px;
      }
      .o-story-card__favcontainer {
        right: -9px;
        width: 200px;
      }
      .o-story-card__infocontainer {
        padding: 0 30px 30px;
        .o-story-card__infodate {
          font-size: 16px;
        }
        .o-story-card__infobottom {
          p {
            font-size: 28px;
          }
        }
      }
    }
  }
`

export default GridContainer
