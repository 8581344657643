import React from "react"
import Search from "../components/template/Search/Search"

const Busqueda = ({ location }) => {
  return (
    <div className="wrapper">
      <Search location={location} />
    </div>
  )
}

export default Busqueda
