import React, { useEffect, useState, useContext } from "react"
import { Container, Col, Row } from "react-awesome-styled-grid"

import { searchStories } from "../../../api/api"
import LayoutContext from "../../layout/layout.context"
import GridResults from "../../modules/search/gridResults/GridResults"
import NoResultsWrapper from "../../modules/wrappers/busquedaWrapper/NoResutsWrapper"
import FooterGeneric from "../../elements/footerGeneric/FooterGeneric"
import BgBlock from "../../elements/bgBlock/BgBlock"
import ButtonIconArrowText from "../../elements/buttonIconArrowText/ButtonIconArrowText"
import SvgExclamation from "../../elements/svgs/SvgExclamation"
import SvgArrowDown from "../../elements/svgs/SvgArrowDown"
import { SearchStyle, ResultsTitle, CounterText, HeaderResults, NoResults, NoResultsTitle, Disclaimer, ButtonContainer, SearchWrapper } from "./Search.style"

const Search = ({ location }) => {
  const templateClass = "t-search"
  /**
  const params = new URLSearchParams(location.search.slice(1));
  const q = params.get('q') || '';
  /* */
  let params = ""
  // let q = '';
  const [stories, setStories] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(1)
  const [total, setTotal] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const { searchQuery, setSearchQuery } = useContext(LayoutContext)

  const fetchData = async () => {
    const result = await searchStories(searchQuery, currentPage, 9)
    if (currentPage > 1) setStories(prev => [...prev, ...result.data])
    else setStories(result.data)
    if (total !== result.pagination.total) setTotal(result.pagination.total)
    if (lastPage !== result.pagination.lastPage) setLastPage(result.pagination.lastPage)
    if (!loaded) setLoaded(true)
  }

  const handleShowMore = () => {
    setCurrentPage(prev => prev + 1)
  }
  /**/
  useEffect(() => {
    const isLocation = location !== undefined
    params = isLocation ? new URLSearchParams(location.search.slice(1)) : ""
    setSearchQuery(params.get("q") || "")
  }, [])
  /**/
  useEffect(() => {
    if (!currentPage) return
    fetchData()
  }, [currentPage])

  useEffect(() => {
    if (currentPage === 1) fetchData()
    else setCurrentPage(1)
  }, [searchQuery])

  return (
    <SearchStyle className={`${total === 0 ? "no-results" : "exist-results"}`}>
      {loaded && (
        <>
          {total > 0 ? (
            <SearchWrapper>
              <BgBlock type="hideTop" theme="dark" depth={0}>
                <Container>
                  <Row>
                    <Col xs={4}>
                      <HeaderResults>
                        <ResultsTitle>Resultados de búsqueda</ResultsTitle>
                        <CounterText>
                          Se han encontrado <span>{total}</span> historias de {searchQuery}
                        </CounterText>
                      </HeaderResults>
                    </Col>
                  </Row>
                </Container>
              </BgBlock>
              <GridResults stories={stories} className={lastPage === currentPage ? "no-more-results" : ""} />
              {lastPage !== currentPage && (
                <ButtonContainer>
                  <ButtonIconArrowText className={`${templateClass}__button-more tag-busqueda-cargar-mas`} iconPosition="bottom" onClick={handleShowMore} icon={<SvgArrowDown />}>
                    Cargar más historias
                  </ButtonIconArrowText>
                </ButtonContainer>
              )}
              <FooterGeneric className={`${templateClass}__footer`} classTag="tag-busqueda-contar-historia" />
            </SearchWrapper>
          ) : (
            <NoResultsWrapper>
              <Container>
                <Row>
                  <Col xs={4}>
                    <NoResults>
                      <SvgExclamation className={`${templateClass}__danger-svg`} />
                      <NoResultsTitle>No se encontraron resultados</NoResultsTitle>
                      <Disclaimer>Recuerda que puedes buscar escribiendo el título de la historia, un estado de la república o el nombre del auto.</Disclaimer>
                    </NoResults>
                  </Col>
                </Row>
              </Container>
            </NoResultsWrapper>
          )}
        </>
      )}
    </SearchStyle>
  )
}

export default Search
