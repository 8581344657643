import * as React from "react"

function SvgArrowDown({ width='13', height = '6.89', className = '' }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 6.89"
    >
      <path d="M6.5 6.89L0 1.39 1.17 0 6.5 4.51 11.83 0 13 1.39z" />
    </svg>
  )
}

export default SvgArrowDown