import React from "react"
import SvgArrowDown from "../svgs/SvgArrowDown"

import ButtonContainer from "./ButtonIconArrowText.style"

const ButtonIconArrowText = ({ children, className = "", onClick }) => {
  return (
    <ButtonContainer onClick={onClick} className={className}>
      <div className="c-button--iconarrow_textcontainer">
        <p className="c-button--iconarrow_text c-button--iconarrow_contentroll">{children}</p>
        <p className="c-button--iconarrow_text c-button--iconarrow_content">{children}</p>
      </div>
      <div className="c-button--iconarrow_iconcontainer">
        <SvgArrowDown className={`c-button--iconarrow_icon c-button--iconarrow_iconroll ${className}`} />
        <SvgArrowDown className={`c-button--iconarrow_icon c-button--iconarrow_iconregular ${className}`} />
      </div>
    </ButtonContainer>
  )
}

export default ButtonIconArrowText
