import styled from "styled-components"
import { device } from "../../../../theme/mediaQueries"
import bgImage1 from "../../../../images/search/jeep-aniversario-busqueda-background-01.jpg"
import bgImage2 from "../../../../images/search/jeep-aniversario-busqueda-background-02.jpg"
import bgImage3 from "../../../../images/search/jeep-aniversario-busqueda-background-03.jpg"

const WrapperStyled = styled.div`
  align-items: center;
  background-image: url(${bgImage3});
  background-position: center;
  background-repeat: repeat;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;

  @media ${device.md} {
    background-image: url(${bgImage2});
  }
  @media ${device.xl} {
    background-image: url(${bgImage1});
  }
`
export default WrapperStyled
