import React from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"

import StoryCard from "../../../elements/storyCard/StoryCard"
import GridContainer from "./GridResults.style"

const GridResults = ({ stories, className = "" }) => {
  const moduleClass = "m-grid-results"
  let countXs = 0
  let countDk = 0
  return (
    <Container>
      <Row>
        <Col xs={4} sm={8} md={12}>
          <GridContainer className={`${moduleClass} ${className}`}>
            {stories.map((story, index) => {
              countXs += 1
              if (countXs > 3) countXs = 1
              countDk += 1
              if (countDk > 4) countDk = 1
              return <StoryCard story={story} key={index} className={`${moduleClass}__story-card ${moduleClass}__story-card_type-xs${countXs} ${moduleClass}__story-card_type-dk${countDk}`} size="small" classTag={`tag-busqueda-${story.tipo}`} />
            })}
          </GridContainer>
        </Col>
      </Row>
    </Container>
  )
}

export default GridResults
