import styled, { css } from "styled-components"
import { device } from "../../../theme/mediaQueries"

const StyledSVGRoll = styled.div`
  .o-svg-roll__typecontainer {
    width: 105px;
    height: 105px;
    position: relative;
    z-index: 3;
    overflow: hidden;
    @media ${device.md} {
      width: 90px;
      height: 90px;
    }
    @media ${device.lg} {
      width: 130px;
      height: 130px;
    }
    @media ${device.xl} {
      width: 150px;
      height: 150px;
    }
  }
  .o-svg-roll__typering {
    width: 100%;
    height: 100%;
    border: 3px solid #ffffff;
    opacity: 0.3;
    border-radius: 50%;
    @media ${device.md} {
      border: 6px solid #ffffff;
    }
  }
  .o-svg-roll__typesvgcontainer {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    @media ${device.lg} {
      width: 40px;
      height: 40px;
    }
  }
  .o-svg-roll__svg {
    color: #ffffff;
    position: absolute;
    transition: all 0.5s ease;
  }
  .o-svg-roll__bgroll {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${props => props.theme.vars.titanWhite};
    z-index: 1;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .o-svg-roll__svgroll {
    transform: translateY(-250%);
    color: ${props => props.theme.vars.outerSpace};
    opacity: 0;

    @media ${device.xl} {
      transform: translateY(-280%);
    }
  }

  ${props =>
    props.mouseOver &&
    css`
      @media ${device.md} {
        .o-svg-roll__bgroll {
          opacity: 1;
        }
        .o-svg-roll__svgroll {
          transform: translateY(0%);
          opacity: 1;
        }
        .o-svg-roll__svgnormal {
          transform: translateY(250%);
          opacity: 0;
        }
        .o-svg-roll__bg {
          transform: scale(1);
        }
      }
      @media ${device.lg} {
        .o-svg-roll__svgnormal {
          transform: translateY(280%);
        }
      }
    `}
`

export default StyledSVGRoll
