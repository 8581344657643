import React, { useState, useRef, useEffect } from "react"
import { withPrefix, Link } from "gatsby"
import lottie from "lottie-web"

import getImgixUrl from "../../../helpers/imgix"

import SvgPromoted from "../svgs/SvgPromoted"
import SvgPromotedSmall from "../svgs/SvgPromotedSmall"
import SVGRoll from "../svgRoll/SVGRoll"
import StyledStoryCard from "./StoryCard.style"

const StoryCard = ({ story, index, className, depth, isOvered = false, isSomeCardIsOvered = false, mouseEnterHandler = () => {}, mouseLeaveHandler = () => {}, size = "default", classTag = "" }) => {
  const classNameID = `o-story-card`
  const path = `${withPrefix("/")}assets/animations/`
  const fecha = new Date(story.date_created)
  const meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"]
  const [mouseOver, setMouseOver] = useState(false)
  const isPromoted = story.etiquetas && story.etiquetas.indexOf("destacadas") !== -1
  const roadLinesLottie = useRef(null)
  const roadLines = useRef(null)

  const mouseOverHandler = () => {
    setMouseOver(true)
    mouseEnterHandler(index)
    if (window.innerWidth >= 1024 && roadLinesLottie.current) roadLinesLottie.current.play()
  }
  const mouseOutHandler = () => {
    setMouseOver(false)
    mouseLeaveHandler(index)
    if (window.innerWidth >= 1024 && roadLinesLottie.current) roadLinesLottie.current.pause()
  }
  // Lotties
  const addLotties = () => {
    roadLinesLottie.current = lottie.loadAnimation({
      container: roadLines.current,
      renderer: "svg",
      loop: true,
      autoplay: false,
      path: `${path}road_lines/data.json`,
    })
  }

  useEffect(() => {
    addLotties()

    return () => {
      if (roadLinesLottie.current) {
        roadLinesLottie.current.stop()
        roadLinesLottie.current.destroy()
        roadLinesLottie.current = null
      }
    }
  }, [])

  return (
    <StyledStoryCard className={`${classNameID} ${classNameID}__set${index} ${isPromoted ? "is-promoted" : ""} ${size === "small" ? "is-small" : ""} ${className} ${isOvered ? "is-overed" : ""} ${!isOvered && isSomeCardIsOvered ? "is-some-card-overed" : ""}`} data-depth={depth}>
      <Link to={`/historias/${story.id}/`} onMouseEnter={mouseOverHandler} onMouseLeave={mouseOutHandler} className={`${classNameID}__link ${classTag}`}>
        <div className={`${classNameID}__bgcontainer`}>
          <div className={`${classNameID}__bg`} style={{ backgroundImage: `url('${getImgixUrl(story.imagen1)}')` }} />
        </div>
        <div className={`${classNameID}__bgnoise`}>
          <div className={`${classNameID}__bgblack`} />
        </div>
        {isPromoted && <div className={`${classNameID}__favcontainer`}>{size === "small" ? <SvgPromotedSmall /> : <SvgPromoted />}</div>}
        <div className={`${classNameID}__roadcontainer`}>
          <div ref={roadLines} />
        </div>
        <SVGRoll type={story.tipo} mouseOver={mouseOver} className={`${classNameID}__svg-roll`} />
        <div className={`${classNameID}__infocontainer`}>
          <div className={`${classNameID}__infotop`}>
            <div className={`${classNameID}__infodate`}>
              {fecha.getDate()} . {meses[fecha.getMonth()]} . {fecha.getFullYear()}
            </div>
          </div>
          <div className={`${classNameID}__infobottom`}>
            <p>{story.titulo}</p>
          </div>
        </div>
      </Link>
    </StyledStoryCard>
  )
}

export default StoryCard
