import React from "react"
import PropTypes from "prop-types"

import SvgTypeAudio from "../svgs/SvgTypeAudio"
import SvgTypeVideo from "../svgs/SvgTypeVideo"
import SvgTypeImage from "../svgs/SvgTypeImage"
import StyledSVGRoll from "./SVGRoll.style"

const SVGRollType = { audio: "audio", video: "video", imagen: "imagen" }

const SVGRoll = ({ type, className, mouseOver }) => {
  const classNameID = `o-svg-roll`

  const getSvgType = (theType, classType) => {
    switch (theType) {
      case "audio":
        return <SvgTypeAudio className={`${classNameID}__${classType} ${classNameID}__svg`} />
      case "video":
        return <SvgTypeVideo className={`${classNameID}__${classType} ${classNameID}__svg`} />
      case "imagen":
        return <SvgTypeImage className={`${classNameID}__${classType} ${classNameID}__svg`} />
      default:
        return null
    }
  }

  return (
    <StyledSVGRoll className={`${classNameID} ${className} `} mouseOver={mouseOver}>
      <div className={`${classNameID}__typecontainer`}>
        <div className={`${classNameID}__typering`} />
        <div className={`${classNameID}__bgroll`} />
        <div className={`${classNameID}__typesvgcontainer`}>
          {getSvgType(type, "svgnormal")}
          {getSvgType(type, "svgroll")}
        </div>
      </div>
    </StyledSVGRoll>
  )
}

SVGRoll.propTypes = {
  mouseOver: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(["audio", "video", "imagen"]),
}

SVGRoll.defaultProps = {
  mouseOver: false,
  className: "",
  type: SVGRollType.video,
}
export default SVGRoll
