import * as React from 'react';

function SvgTypeVideo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 32.4" {...props}>
      <defs>
        <style>{'.prefix__cls-1{fill:currentColor}'}</style>
      </defs>
      <g id="prefix__camera-alt">
        <circle className="prefix__cls-1" cx={18} cy={18} r={5.76} />
        <path
          className="prefix__cls-1"
          d="M12.6 1.8L9.36 5.4H3.6A3.61 3.61 0 000 9v21.6a3.61 3.61 0 003.6 3.6h28.8a3.61 3.61 0 003.6-3.6V9a3.61 3.61 0 00-3.6-3.6h-5.76L23.4 1.8zm5.4 27a9 9 0 119-9 8.91 8.91 0 01-9 9z"
          transform="translate(0 -1.8)"
        />
      </g>
    </svg>
  );
}

export default SvgTypeVideo;
