import * as React from 'react';

function SvgTypeVideo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
      <path fill="currentColor" d="M9 0v36l18-18z" />
    </svg>
  );
}

export default SvgTypeVideo;
