import * as React from 'react';

function SvgTypeAudio(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
      <path
        d="M17.3 22.6c3 .1 5.4-2.3 5.5-5.3V6.2c.1-3-2.3-5.4-5.3-5.5h-.2C14.3.6 11.9 3 11.8 6v11.1c-.1 3 2.3 5.4 5.2 5.5h.3zm9.6-5.5c-.1 5.2-4.3 9.3-9.5 9.3h-.1c-5.2.1-9.5-4-9.6-9.2v-.1H4.5c0 6.2 4.9 11.3 10.9 12.2v6H19v-6c6-.9 10.9-6 10.9-12.2h-3z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTypeAudio;
