import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

const ButtonContainer = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 230px;
  min-width: 50px;
  padding: 0 20px;
  outline: none;
  border: none;
  margin: 0px auto;
  background-color: transparent;
  cursor: pointer;

  .c-button--iconarrow_textcontainer {
    display: block;
    pointer-events: none;
    position: relative;
    width: 230px;
    text-align: center;
  }
  .c-button--iconarrow_text {
    display: block;
    font-family: ${props => props.theme.vars.tradeGot};
    font-size: 14px;
    font-weight: ${props => props.theme.vars.fontBold};
    color: ${props => props.theme.vars.white};
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    width: 100%;
    text-align: center;
    transition: all 0.5s ease;
  }
  .c-button--iconarrow_contentroll {
    color: ${props => props.theme.vars.mustard};
    opacity: 0;
  }
  .c-button--iconarrow_iconcontainer {
    margin-top: 25px;
    overflow: hidden;
    pointer-events: none;
  }
  .c-button--iconarrow_icon {
    position: absolute;
    fill: ${props => props.theme.vars.mustard};
    margin-left: -6.5px;
    transition: all 0.5s ease;
  }
  .c-button--iconarrow_iconroll {
    transform: translateY(-20px);
    opacity: 0;
    fill: ${props => props.theme.vars.white};
  }

  &:hover {
    .c-button--iconarrow_content {
      opacity: 0;
    }
    .c-button--iconarrow_contentroll {
      opacity: 1;
    }
    .c-button--iconarrow_iconroll {
      transform: translateY(0);
      opacity: 1;
    }
    .c-button--iconarrow_iconregular {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  @media ${device.sm} {
    height: 55px;
    width: 280px;
    .c-button--iconarrow_textcontainer {
      width: 280px;
    }
    .c-button--iconarrow_iconcontainer {
      margin-top: 30px;
    }
  }

  @media ${device.md} {
    .c-button--iconarrow_iconcontainer {
      margin-top: 28px;
    }
  }

  @media ${device.lg} {
    width: 312px;
    height: 60px;
    .c-button--iconarrow_textcontainer {
      width: 312px;
    }
    .c-button--iconarrow_text {
      font-size: 16px;
    }
    .c-button--iconarrow_iconcontainer {
      margin-top: 40px;
    }
    .c-button--iconarrow_iconroll {
      transform: translateY(-40px);
    }
    &:hover {
      .c-button--iconarrow_iconregular {
        transform: translateY(40px);
      }
    }
  }
`
export default ButtonContainer
