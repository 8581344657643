import React from 'react';

import WrapperStyled from './NoResultsWrapper';

const NoResultsWrapper = ({ children, className = '' }) => {
  return (
    <WrapperStyled className={`c-wrapper--noresults ${className}`}>
      {children}
    </WrapperStyled>
  );
};

export default NoResultsWrapper;
